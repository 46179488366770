import React from "react"
import { graphql } from "gatsby"

export default ({data}) => {
    const pathList = data.pathList;
    return (
        <div>
            <strong><h1>Links</h1></strong>
            <ul>
                {console.log(data)}
                {pathList.edges.map(({node}) => {
                    let link = `/${node.relativePath.replace('localized/', '')}`;
                    return <li key={node.base}><a target="_placeholder" rel="noopener noreferrer" href={`${link}`}>{link}</a></li>
                })}
            </ul>
        </div>
    )
}

export const query = graphql`
query ($pathList: [String!]){
    pathList: allDirectory (filter: {absolutePath: {in: $pathList}}) {
      edges {
        node {
          absolutePath
          relativePath
          base
          name
        }
      }
    }
  }
`